import React from 'react';
import { Text } from '../../components';
import { Buttons } from './Buttons';
import { LightBlockTitle, ToolButtons, ToolWrapper } from './styled';

import phoneImgPath from '../../assets/clinics/Image.png';

export const ToolBlock = () => {
  const isMobile = window.screen.availWidth < 820;

  return (
    <ToolWrapper>
      <LightBlockTitle id="clinics">
        Удобный инструмент для благотворной работы с пациентами
      </LightBlockTitle>
      <br />
      <Text size={18} lineHeight={25} color="#FFF" pt={8}>
        Устанавливайте приложение, подключайтесь к партнерской программе и начинайте изменять мир к
        лучшему вместе с нами. Чтобы пользоваться приложением как партнер Doctorly - оставьте заявку
        на сайте или напишите на mail@doctorly.ru
      </Text>

      <ToolButtons>
        <Buttons />
      </ToolButtons>

      {isMobile && <img src={phoneImgPath} alt="phones" />}
    </ToolWrapper>
  );
};
