export const Rustore = () => (
  <svg width="144" height="48" viewBox="0 0 144 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6.85717C0 3.07004 2.71457 0 6.06316 0H137.937C141.285 0 144 3.07005 144 6.85717V41.1428C144 44.9299 141.285 48 137.937 48H6.06316C2.71457 48 0 44.9299 0 41.1428V6.85717Z"
      fill="black"
    />
    <path
      d="M12.7544 37.9959C15.0765 40.2855 18.8139 40.2855 26.2886 40.2855H27.6099C35.0846 40.2855 38.8219 40.2855 41.144 37.9959C43.466 35.7064 43.466 32.0213 43.466 24.6511V23.3483C43.466 15.9782 43.466 12.2932 41.144 10.0036C38.8219 7.71406 35.0846 7.71406 27.6099 7.71406H26.2886C18.8139 7.71406 15.0765 7.71406 12.7544 10.0036C10.4324 12.2932 10.4324 15.9782 10.4324 23.3483V24.6511C10.4324 32.0213 10.4324 35.7064 12.7544 37.9959Z"
      fill="#0077FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.9351 29.3631L33.7031 28.8133C33.4343 28.7372 33.2445 28.4988 33.235 28.2217L32.9564 20.1216C32.8758 19.0495 32.0687 18.1968 31.2253 17.9457C31.1779 17.9316 31.1274 17.9506 31.0993 17.9908C31.0708 18.0317 31.0818 18.0882 31.1215 18.1184C31.3299 18.2775 31.9031 18.7962 31.9031 19.6911L31.9014 30.2817C31.9014 31.3043 30.9214 32.0543 29.9106 31.8054L27.644 31.2471C27.3928 31.1604 27.2181 30.9296 27.209 30.6634L26.9305 22.5628C26.8499 21.4907 26.0428 20.6381 25.1994 20.3869C25.152 20.3729 25.1014 20.3918 25.0734 20.4319C25.0449 20.4728 25.0559 20.5293 25.0956 20.5597C25.3039 20.7186 25.8772 21.2374 25.8772 22.1322L25.8755 30.9601L25.8768 30.9603V32.7239C25.8768 33.7465 24.8967 34.4966 23.8858 34.2475L17.484 32.6707C16.594 32.4515 15.97 31.6665 15.97 30.7662V20.159C15.97 19.1364 16.95 18.3864 17.9609 18.6354L21.9947 19.629V17.7168C21.9947 16.6942 22.9747 15.9441 23.9856 16.1932L28.0192 17.1867V15.2745C28.0192 14.2519 28.9992 13.5018 30.0102 13.7509L36.412 15.3277C37.302 15.5469 37.926 16.3319 37.926 17.2323V27.8394C37.926 28.862 36.946 29.612 35.9351 29.3631Z"
      fill="white"
    />
    <path
      d="M62.8131 37.7142H60.2886L56.2829 32.2012H54.1757V37.7142H52.0267V23.2114H57.2009C58.2023 23.2114 59.0994 23.4034 59.8923 23.7874C60.685 24.1714 61.297 24.7062 61.7282 25.392C62.1733 26.064 62.3958 26.8388 62.3958 27.7166C62.3958 28.8138 62.055 29.7532 61.3735 30.5348C60.692 31.3028 59.781 31.8102 58.6404 32.0572L62.8131 37.7142ZM54.1757 25.1658V30.2674H57.2009C58.091 30.2674 58.8074 30.0342 59.3498 29.568C59.9061 29.088 60.1843 28.4708 60.1843 27.7166C60.1843 26.9622 59.9061 26.352 59.3498 25.8858C58.8074 25.4058 58.091 25.1658 57.2009 25.1658H54.1757Z"
      fill="white"
    />
    <path
      d="M68.5152 37.92C67.7502 37.92 67.0617 37.7348 66.4497 37.3646C65.8377 36.9806 65.3578 36.4594 65.0101 35.8012C64.6624 35.1292 64.4886 34.3748 64.4886 33.5382V27.0788H66.5332V33.3738C66.5332 34.1828 66.7626 34.8412 67.2216 35.3486C67.6806 35.856 68.2718 36.1098 68.995 36.1098C69.7461 36.1098 70.372 35.8492 70.8727 35.328C71.3734 34.8068 71.6238 34.1554 71.6238 33.3738V27.0788H73.6685V37.7142H72.208L71.7281 36.1098C71.3804 36.6858 70.9214 37.1314 70.3511 37.4468C69.7948 37.7622 69.1828 37.92 68.5152 37.92Z"
      fill="white"
    />
    <path
      d="M81.2906 37.92C79.9414 37.92 78.7661 37.5772 77.7646 36.8914C76.7771 36.192 76.1025 35.2458 75.7409 34.0526L77.8063 33.7234C78.2097 34.4914 78.6896 35.0606 79.246 35.4308C79.8023 35.7874 80.4838 35.9658 81.2906 35.9658C82.1112 35.9658 82.7788 35.7874 83.2935 35.4308C83.8081 35.0606 84.0654 34.56 84.0654 33.9292C84.0654 33.4628 83.9193 33.072 83.6273 32.7566C83.349 32.4412 82.9944 32.1874 82.5632 31.9954C82.1321 31.8034 81.5687 31.5908 80.8733 31.3578C79.9414 31.056 79.1764 30.7542 78.5783 30.4526C77.9802 30.1508 77.4726 29.7258 77.0553 29.1772C76.6519 28.6148 76.4502 27.9018 76.4502 27.0378C76.4502 26.2286 76.6519 25.5222 77.0553 24.9188C77.4726 24.3018 78.0428 23.8286 78.7661 23.4994C79.4894 23.1702 80.2961 23.0058 81.1863 23.0058C82.4241 23.0058 83.4882 23.328 84.3784 23.9726C85.2685 24.6034 85.9014 25.4742 86.277 26.5852L84.2532 26.9142C83.975 26.2834 83.5786 25.8034 83.0639 25.4742C82.5493 25.1314 81.9443 24.96 81.2489 24.96C80.4978 24.96 79.8788 25.1382 79.392 25.4948C78.9052 25.8378 78.6618 26.3108 78.6618 26.9142C78.6618 27.3668 78.8009 27.7508 79.0791 28.0662C79.3711 28.3818 79.7258 28.6354 80.1431 28.8274C80.5743 29.0194 81.1446 29.2388 81.8539 29.4858C82.7579 29.7874 83.509 30.096 84.1071 30.4114C84.7191 30.7132 85.2337 31.1452 85.651 31.7074C86.0682 32.2698 86.277 32.976 86.277 33.8262C86.277 34.6492 86.0544 35.3692 85.6093 35.9862C85.1781 36.6034 84.5801 37.0834 83.815 37.4262C83.0639 37.7554 82.2224 37.92 81.2906 37.92Z"
      fill="white"
    />
    <path
      d="M94.9135 35.9452L94.7262 37.7142H92.8274C91.7003 37.7142 90.8455 37.44 90.2612 36.8914C89.6769 36.3428 89.3848 35.5542 89.3848 34.5258V28.8686H87.5279V27.0788H89.3848L89.9483 23.9932H91.4289V27.0788H94.7262V28.8686H91.4289V34.5258C91.4289 35.472 91.8951 35.9452 92.8274 35.9452H94.9135Z"
      fill="white"
    />
    <path
      d="M101.732 37.92C100.633 37.92 99.6526 37.6868 98.7905 37.2206C97.9283 36.7542 97.2535 36.1028 96.7662 35.2662C96.2797 34.4298 96.036 33.4766 96.036 32.4068C96.036 31.3372 96.2797 30.384 96.7662 29.5474C97.2535 28.7108 97.9283 28.0594 98.7905 27.5932C99.6526 27.1132 100.633 26.8732 101.732 26.8732C102.831 26.8732 103.811 27.1132 104.673 27.5932C105.536 28.0594 106.21 28.7108 106.698 29.5474C107.184 30.384 107.428 31.3372 107.428 32.4068C107.428 33.4766 107.184 34.4298 106.698 35.2662C106.21 36.1028 105.536 36.7542 104.673 37.2206C103.811 37.6868 102.831 37.92 101.732 37.92ZM101.732 36.0686C102.789 36.0686 103.644 35.7326 104.298 35.0606C104.966 34.3886 105.3 33.504 105.3 32.4068C105.3 31.3098 104.966 30.4252 104.298 29.7532C103.644 29.0674 102.789 28.7246 101.732 28.7246C100.674 28.7246 99.8197 29.0674 99.1662 29.7532C98.5117 30.4252 98.1849 31.3098 98.1849 32.4068C98.1849 33.4902 98.5117 34.3748 99.1662 35.0606C99.8197 35.7326 100.674 36.0686 101.732 36.0686Z"
      fill="white"
    />
    <path
      d="M109.62 37.7142V27.0788H111.102L111.54 28.6012C111.859 28.0662 112.256 27.6822 112.729 27.4492C113.202 27.2022 113.766 27.0788 114.419 27.0788H114.919L115.086 29.0126H113.918C113.167 29.0126 112.603 29.2252 112.229 29.6502C111.853 30.0754 111.665 30.6514 111.665 31.3782V37.7142H109.62Z"
      fill="white"
    />
    <path
      d="M126.775 32.3246C126.775 32.5988 126.769 32.8114 126.755 32.9622H117.846C117.957 33.9086 118.333 34.6628 118.973 35.2252C119.612 35.7874 120.426 36.0686 121.413 36.0686C122.095 36.0686 122.7 35.9382 123.229 35.6778C123.771 35.4034 124.182 35.0468 124.459 34.608L126.379 34.9166C125.962 35.8766 125.287 36.6172 124.355 37.1382C123.438 37.6594 122.415 37.92 121.289 37.92C120.218 37.92 119.258 37.6868 118.41 37.2206C117.561 36.7406 116.9 36.0822 116.428 35.2458C115.954 34.4092 115.718 33.4628 115.718 32.4068C115.718 31.3372 115.954 30.384 116.428 29.5474C116.9 28.7108 117.554 28.0594 118.388 27.5932C119.223 27.1132 120.175 26.8732 121.247 26.8732C122.304 26.8732 123.249 27.1132 124.084 27.5932C124.933 28.0594 125.593 28.7108 126.066 29.5474C126.539 30.3702 126.775 31.296 126.775 32.3246ZM121.247 28.6628C120.426 28.6628 119.717 28.9028 119.118 29.3828C118.534 29.8628 118.145 30.5074 117.95 31.3166H124.543C124.362 30.5212 123.973 29.8834 123.375 29.4034C122.79 28.9098 122.082 28.6628 121.247 28.6628Z"
      fill="white"
    />
    <path
      d="M55.067 17.6491C54.3359 17.6491 53.6787 17.4793 53.0953 17.1396C52.5119 16.7925 52.0541 16.3125 51.7218 15.6996C51.3968 15.0867 51.2344 14.3962 51.2344 13.6282C51.2344 12.8602 51.3968 12.1734 51.7218 11.5679C52.0541 10.955 52.5082 10.4787 53.0842 10.139C53.6676 9.79191 54.3248 9.61837 55.0559 9.61837C55.8461 9.61837 56.5476 9.81775 57.1605 10.2165C57.7808 10.6079 58.2239 11.1396 58.4898 11.8116L57.3599 11.9888C57.1531 11.5753 56.8467 11.2541 56.4405 11.0251C56.0418 10.7888 55.5913 10.6707 55.0891 10.6707C54.5722 10.6707 54.1107 10.7962 53.7045 11.0473C53.3058 11.2984 52.9919 11.6491 52.763 12.0996C52.5414 12.5427 52.4307 13.0522 52.4307 13.6282C52.4307 14.2116 52.5414 14.7285 52.763 15.179C52.9919 15.6221 53.3094 15.9691 53.7156 16.2202C54.1218 16.4713 54.587 16.5968 55.1113 16.5968C55.6282 16.5968 56.0861 16.4787 56.4848 16.2424C56.891 15.9987 57.2011 15.659 57.4153 15.2233L58.5451 15.4005C58.2645 16.0873 57.8104 16.6338 57.1827 17.0399C56.555 17.4461 55.8498 17.6491 55.067 17.6491Z"
      fill="white"
    />
    <path
      d="M64.333 17.5384H62.9041L60.7884 15.1679H60.445V17.5384H59.3595V11.8116H60.445V14.1267H60.7773L62.8044 11.8116H64.1115L61.6524 14.5808L64.333 17.5384Z"
      fill="white"
    />
    <path
      d="M66.9145 11.7008C67.6234 11.7008 68.181 11.9334 68.5871 12.3987C69.0006 12.8565 69.2074 13.499 69.2074 14.3261V17.5384H68.432L68.1773 16.6411C67.9927 16.9291 67.7453 17.1691 67.4351 17.3611C67.125 17.5531 66.752 17.6491 66.3163 17.6491C65.9323 17.6491 65.589 17.5716 65.2862 17.4165C64.9834 17.2614 64.7471 17.0473 64.5773 16.7741C64.4074 16.4934 64.3225 16.1796 64.3225 15.8325C64.3225 15.2934 64.5366 14.8614 64.965 14.5365C65.3933 14.2116 66.0136 14.0491 66.8259 14.0491H68.1219C68.0923 13.5987 67.9668 13.2516 67.7453 13.0079C67.5237 12.7568 67.2283 12.6313 66.8591 12.6313C66.5785 12.6313 66.3311 12.6978 66.117 12.8307C65.9028 12.9636 65.7403 13.1371 65.6296 13.3513L64.6216 13.1851C64.7693 12.7273 65.0536 12.3654 65.4745 12.0996C65.8954 11.8338 66.3754 11.7008 66.9145 11.7008ZM66.549 16.7298C67.0142 16.7298 67.3908 16.5784 67.6788 16.2756C67.9742 15.9654 68.1219 15.5408 68.1219 15.0018V14.9353H66.8591C65.8991 14.9353 65.4191 15.2233 65.4191 15.7993C65.4191 16.0799 65.5225 16.3051 65.7293 16.475C65.936 16.6448 66.2093 16.7298 66.549 16.7298Z"
      fill="white"
    />
    <path
      d="M73.4613 15.4559C73.2841 15.5371 73.0736 15.5999 72.8299 15.6442C72.5863 15.6885 72.3463 15.7107 72.1099 15.7107C71.4232 15.7107 70.8952 15.5187 70.5259 15.1347C70.1567 14.7507 69.9721 14.2079 69.9721 13.5064V11.8116H71.0576V13.5064C71.0576 14.3261 71.4527 14.7359 72.2429 14.7359C72.7229 14.7359 73.129 14.651 73.4613 14.4811V11.8116H74.5469V17.5384H73.4613V15.4559Z"
      fill="white"
    />
    <path
      d="M78.0784 11.7008C78.7873 11.7008 79.3448 11.9334 79.751 12.3987C80.1645 12.8565 80.3713 13.499 80.3713 14.3261V17.5384H79.5959L79.3411 16.6411C79.1565 16.9291 78.9091 17.1691 78.599 17.3611C78.2888 17.5531 77.9159 17.6491 77.4802 17.6491C77.0962 17.6491 76.7528 17.5716 76.4501 17.4165C76.1473 17.2614 75.911 17.0473 75.7411 16.7741C75.5713 16.4934 75.4864 16.1796 75.4864 15.8325C75.4864 15.2934 75.7005 14.8614 76.1288 14.5365C76.5571 14.2116 77.1774 14.0491 77.9898 14.0491H79.2858C79.2562 13.5987 79.1307 13.2516 78.9091 13.0079C78.6876 12.7568 78.3922 12.6313 78.023 12.6313C77.7424 12.6313 77.495 12.6978 77.2808 12.8307C77.0667 12.9636 76.9042 13.1371 76.7934 13.3513L75.7854 13.1851C75.9331 12.7273 76.2174 12.3654 76.6384 12.0996C77.0593 11.8338 77.5393 11.7008 78.0784 11.7008ZM77.7128 16.7298C78.1781 16.7298 78.5547 16.5784 78.8427 16.2756C79.1381 15.9654 79.2858 15.5408 79.2858 15.0018V14.9353H78.023C77.063 14.9353 76.583 15.2233 76.583 15.7993C76.583 16.0799 76.6864 16.3051 76.8931 16.475C77.0999 16.6448 77.3731 16.7298 77.7128 16.7298Z"
      fill="white"
    />
    <path
      d="M83.1852 17.5384H81.579V11.8116H82.6314V16.3199H82.7975L85.1126 11.8116H86.7187V17.5384H85.6664V13.0411H85.5003L83.1852 17.5384ZM84.16 11.0694C83.68 11.0694 83.2775 10.9439 82.9526 10.6928C82.635 10.4344 82.4467 10.0504 82.3877 9.54083H83.2738C83.3107 9.77714 83.403 9.95806 83.5507 10.0836C83.7058 10.2018 83.9089 10.2608 84.16 10.2608C84.411 10.2608 84.6104 10.2018 84.7581 10.0836C84.9132 9.95806 85.0055 9.77714 85.035 9.54083H85.9323C85.8658 10.0504 85.6701 10.4344 85.3452 10.6928C85.0277 10.9439 84.6326 11.0694 84.16 11.0694Z"
      fill="white"
    />
    <path
      d="M90.492 17.5384H89.4066V12.8085H87.4239V11.8116H92.4748V12.8085H90.492V17.5384Z"
      fill="white"
    />
    <path
      d="M98.5191 14.6362C98.5191 14.7839 98.5154 14.8984 98.508 14.9796H93.7782C93.8372 15.4891 94.0366 15.8953 94.3763 16.1981C94.716 16.5008 95.148 16.6522 95.6723 16.6522C96.0341 16.6522 96.3554 16.5821 96.636 16.4418C96.924 16.2941 97.1419 16.1021 97.2895 15.8658L98.3086 16.0319C98.0871 16.5488 97.7289 16.9476 97.2342 17.2282C96.7468 17.5088 96.204 17.6491 95.6058 17.6491C95.0372 17.6491 94.5277 17.5236 94.0772 17.2725C93.6268 17.0141 93.276 16.6596 93.0249 16.2091C92.7738 15.7587 92.6483 15.2491 92.6483 14.6805C92.6483 14.1045 92.7738 13.5913 93.0249 13.1408C93.276 12.6904 93.6231 12.3396 94.0662 12.0885C94.5092 11.8301 95.0151 11.7008 95.5837 11.7008C96.1449 11.7008 96.6471 11.8301 97.0902 12.0885C97.5406 12.3396 97.8914 12.6904 98.1425 13.1408C98.3935 13.5839 98.5191 14.0824 98.5191 14.6362ZM95.5837 12.6645C95.148 12.6645 94.7714 12.7938 94.4538 13.0522C94.1437 13.3107 93.9369 13.6578 93.8335 14.0934H97.3338C97.2378 13.6651 97.0311 13.3218 96.7135 13.0633C96.4034 12.7974 96.0268 12.6645 95.5837 12.6645Z"
      fill="white"
    />
    <path
      d="M103.393 17.5384H101.787V11.8116H102.839V16.3199H103.005L105.32 11.8116H106.926V17.5384H105.874V13.0411H105.708L103.393 17.5384Z"
      fill="white"
    />
    <path
      d="M111.32 14.6141C111.623 14.7027 111.874 14.8651 112.074 15.1014C112.273 15.3304 112.373 15.6147 112.373 15.9544C112.373 16.2719 112.28 16.5599 112.096 16.8184C111.911 17.0694 111.645 17.2725 111.298 17.4276C110.958 17.5753 110.563 17.6491 110.113 17.6491C109.507 17.6491 108.987 17.5088 108.551 17.2282C108.115 16.9402 107.827 16.5562 107.687 16.0762L108.695 15.9101C108.939 16.4418 109.408 16.7076 110.102 16.7076C110.486 16.7076 110.778 16.6374 110.977 16.4971C111.184 16.3494 111.287 16.1464 111.287 15.8879C111.287 15.659 111.191 15.4744 110.999 15.3341C110.814 15.1938 110.515 15.1236 110.102 15.1236H109.349V14.171H110.102C110.456 14.171 110.726 14.1045 110.91 13.9716C111.102 13.8387 111.198 13.6614 111.198 13.4399C111.198 13.2036 111.095 13.0116 110.888 12.8639C110.689 12.7162 110.442 12.6424 110.146 12.6424C109.858 12.6424 109.6 12.7125 109.371 12.8528C109.142 12.9931 108.968 13.1851 108.85 13.4288L107.842 13.2516C108.012 12.7568 108.304 12.3765 108.717 12.1107C109.138 11.8374 109.626 11.7008 110.179 11.7008C110.578 11.7008 110.936 11.771 111.254 11.9113C111.579 12.0516 111.83 12.2473 112.007 12.4984C112.192 12.7494 112.284 13.0301 112.284 13.3402C112.284 13.6578 112.195 13.9273 112.018 14.1488C111.841 14.3704 111.608 14.5254 111.32 14.6141Z"
      fill="white"
    />
  </svg>
);
