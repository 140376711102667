import React from 'react';
import { Text } from '../../components';
import { AdvantageItems, AdvantagesContainer, AdvantageTitle, Item } from './styled';

import connectImgPath from '../../assets/clinics/connect.svg';
import patientImgPath from '../../assets/clinics/patient.svg';
import socialImgPath from '../../assets/clinics/social.svg';
import telemedImgPath from '../../assets/clinics/telemed.svg';

export const AdvantagesBlock = () => {
  return (
    <AdvantagesContainer id="doctors">
      <AdvantageTitle>Преимущества сотрудничества:</AdvantageTitle>
      <Text size={18} lineHeight={25}>
        Мы сотрудничаем как с клиниками, так и с индивидуальными специалистами. Все наши партнеры
        проходят интервью, поэтому пользователям можно гарантировать высокое качество
        предоставляемых услуг.
      </Text>

      <AdvantageItems>
        <Item>
          <img src={connectImgPath} alt="connect" />
          <Text size={24} lineHeight={29} weight={600}>
            Быстрое подключение
          </Text>
          <Text size={18} lineHeight={25}>
            После заявки свяжемся с вами в самое короткое время, готовим договор и прокачиваем ваш
            аккаунт до специалиста.
          </Text>
        </Item>

        <Item>
          <img src={patientImgPath} alt="patient" />
          <Text size={24} lineHeight={29} weight={600}>
            Связь с пациентом
          </Text>
          <Text size={18} lineHeight={25}>
            Бывает так, что пациенту стало плохо в выходной день или вечером и даже простой ответ на
            вопрос в чате очень поможет. Лояльность клиента гарантирована!
          </Text>
        </Item>

        <Item>
          <img src={socialImgPath} alt="social" />
          <Text size={24} lineHeight={29} weight={600}>
            Социальная сеть
          </Text>
          <Text size={18} lineHeight={25}>
            В приложении Doctorly вы можете рассказывать о себе и привлекать клиентов с помощью
            ленты публикаций: посты с изображениями или короткими видео-роликами и текстовым
            описанием позволят людям узнать о вас.
          </Text>
        </Item>

        <Item>
          <img src={telemedImgPath} alt="telemed" />
          <Text size={24} lineHeight={29} weight={600}>
            Телемедицина
          </Text>
          <Text size={18} lineHeight={25}>
            Развивать свой сервис телемедицины - дорого и долго. Еще сложнее убедить пользователя
            пользоваться именно вашим сервисом. Эти сложности мы взяли на себя.
          </Text>
        </Item>
      </AdvantageItems>
    </AdvantagesContainer>
  );
};
