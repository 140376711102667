import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppButton, PageContainer, Text } from '../../components';
import { BackButton, Container, Wrapper, Bottom } from './styled';

import arrowPath from '../../assets/icons/back-arrow.svg';
import { Select } from './Select';

export const SubscriptionPage = () => {
  const [pickerValue, setPickerValue] = useState({
    type: '12',
  });

  const navigate = useNavigate();

  const onBack = () => {
    navigate('/auth');
  };

  const onChange = (value: { type: string }) => {
    setPickerValue(value);
  };

  return (
    <PageContainer isFull>
      <Container>
        <Wrapper>
          <div style={{ position: 'relative' }}>
            <BackButton onClick={onBack}>
              <img src={arrowPath} alt="arrow" />
            </BackButton>
            <Text pb={16} size={16} weight={600} textAlign="center">
              Покупка
            </Text>
          </div>

          <Text size={14} lineHeight={21} weight={600}>
            Премиум подписка
          </Text>
          <Text size={14} lineHeight={21}>
            Проконсультируйтесь с узко или широко профильным врачом по вашему состоянию прямо
            сейчас. Консультация происходит онлайн в режим общения через чат.
          </Text>
          <Text size={14} lineHeight={21} weight={600}>
            Что входит?
          </Text>
          <ul>
            <li style={{ fontSize: 14, lineHeight: '21px' }}>Неограниченные онлайн консультации</li>
            <li style={{ fontSize: 14, lineHeight: '21px' }}>
              Полный доступ ко всем функциям приложения
            </li>
            <li style={{ fontSize: 14, lineHeight: '21px' }}>Поддержка 24/7</li>
          </ul>

          <Text pt={8} size={14} lineHeight={21} weight={600}>
            Как пользоваться?
          </Text>
          <div>
            <Text size={14} lineHeight={21} weight={600}>
              1. Оплатите подписку.
            </Text>
            <Text size={14} lineHeight={21}>
              Подписка становится доступна сразу после оплаты и действует в течение указанного срока
              с момента покупки.
            </Text>
          </div>

          <div>
            <Text size={14} lineHeight={21} weight={600}>
              2. Сформулируйте проблему.
            </Text>
            <Text size={14} lineHeight={21}>
              Опишите свою проблему в чате Докторли. Администратор подберет вам специалиста и
              организует чат с ним.
            </Text>
          </div>

          <div>
            <Text size={14} lineHeight={21} weight={600}>
              3. Пообщайтесь со специалистом.
            </Text>
            <Text size={14} lineHeight={21}>
              Общайтесь с врачом по интересующим вас вопросам. Опишите свое состояние и то что вас
              беспокоит.
            </Text>
          </div>

          <Text size={12} lineHeight={16} textAlign="center">
            Нажимая “Купить подписку”, вы соглашаетесь с условиями договора на оказание услуг
          </Text>
        </Wrapper>

        <Bottom>
          <Select pickerValue={pickerValue} onChange={onChange} />
          <AppButton title="Купить подписку" bold fullWidth />
        </Bottom>
      </Container>
    </PageContainer>
  );
};
