import React from 'react';
import { Text } from '../../components';
import { AdvantagesContainer, AdvantageTitle, OfferItem, Offers, OffersRow } from './styled';

import figureImgPath from '../../assets/clinics/feature.svg';

export const OfferBlock = () => {
  return (
    <AdvantagesContainer>
      <AdvantageTitle>Что еще вы сможете предложить своим пациентам:</AdvantageTitle>

      <Offers>
        <OffersRow>
          <img src={figureImgPath} alt="figure" />
          <OfferItem>
            <Text size={24} lineHeight={29} weight={600}>
              Быстрое подключение
            </Text>
            <Text size={18} lineHeight={25}>
              После заявки свяжемся с вами в самое короткое время, готовим договор и прокачиваем ваш
              аккаунт до специалиста.
            </Text>
          </OfferItem>
        </OffersRow>

        <OffersRow>
          <img src={figureImgPath} alt="figure" />
          <OfferItem>
            <Text size={24} lineHeight={29} weight={600}>
              Связь с пациентом
            </Text>
            <Text size={18} lineHeight={25}>
              Бывает так, что пациенту стало плохо в выходной день или вечером и даже простой ответ
              на вопрос в чате очень поможет. Лояльность клиента гарантирована!
            </Text>
          </OfferItem>
        </OffersRow>

        <OffersRow>
          <img src={figureImgPath} alt="figure" />
          <OfferItem>
            <Text size={24} lineHeight={29} weight={600}>
              Социальная сеть
            </Text>
            <Text size={18} lineHeight={25}>
              В приложении Doctorly вы можете рассказывать о себе и привлекать клиентов с помощью
              ленты публикаций: посты с изображениями или короткими видео-роликами и текстовым
              описанием позволят людям узнать о вас.
            </Text>
          </OfferItem>
        </OffersRow>

        <OffersRow>
          <img src={figureImgPath} alt="figure" />
          <OfferItem>
            <Text size={24} lineHeight={29} weight={600}>
              Телемедицина
            </Text>
            <Text size={18} lineHeight={25}>
              Развивать свой сервис телемедицины - дорого и долго. Еще сложнее убедить пользователя
              пользоваться именно вашим сервисом. Эти сложности мы взяли на себя.
            </Text>
          </OfferItem>
        </OffersRow>
      </Offers>
    </AdvantagesContainer>
  );
};
