import React from 'react';
import * as Scroll from 'react-scroll';
import { Link } from 'react-router-dom';
import { LogoIcon, Text } from '../../components';
import { HeaderContainer, LogoWrapper, MenuContainer, SignInButton, MobileHeader } from './styled';

const ScrollLink = Scroll.Link;

export const Header = () => {
  return (
    <HeaderContainer>
      <MenuContainer>
        <ScrollLink to="main" spy={true} smooth={true} offset={-50}>
          <Text size={18} weight={700}>
            Главная
          </Text>
        </ScrollLink>
        <ScrollLink to="clinics" spy={true} smooth={true}>
          <Text size={18} weight={700}>
            Партнерство для клиник
          </Text>
        </ScrollLink>
        <ScrollLink to="doctors" spy={true} smooth={true} offset={-50}>
          <Text size={18} weight={700}>
            Партнерство для врачей
          </Text>
        </ScrollLink>
        <Link to="/auth">
          <SignInButton>Войти</SignInButton>
        </Link>
      </MenuContainer>

      <LogoWrapper>
        <LogoIcon />
      </LogoWrapper>

      <MobileHeader>
        <Link to="/auth">
          <SignInButton>Войти</SignInButton>
        </Link>
      </MobileHeader>
    </HeaderContainer>
  );
};
