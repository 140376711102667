import React, { FC, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MatrixUser, ProfileType, SettingsType } from '../../../types';
import { PostIcon, Text, AppButton, Avatar } from '../../../components';
import { authProvider } from '../../../utils';
import {
  ProfileContainer,
  ProfileWrapper,
  NameWrapper,
  ProfileTabList,
  ProfileTab,
  ButtonWrapper,
} from './styled';

import checkImgPath from '../../../assets/icons/check-surveys.svg';
import logoutPath from '../../../assets/icons/logout.svg';
// import calendarPath from '../../../assets/icons/calendar.svg';
import cardPath from '../../../assets/icons/card.svg';
import pillsPath from '../../../assets/icons/pills.svg';
import { API, CHAT_HOST, IS_DEV } from '../../../const';

export type ProfileProps = {
  profile?: ProfileType;
  onUploadAvatar?: (file: File) => Promise<void>;
  onSave?: (values: SettingsType) => void;
  goBack?: () => void;
};

export const Profile: FC<ProfileProps> = ({ profile }) => {
  const { logout, token } = authProvider();
  const navigate = useNavigate();

  const avatarPath = profile?.avatar;

  const isDoctor = useMemo(() => {
    return profile?.is_manager || profile?.type === 'doctor' || profile?.type === 'clinic';
  }, [profile]);

  const onLogout = () => {
    logout();
    navigate('/auth');
  };

  const onOpenChat = async () => {
    try {
      const response = await fetch(`${API}/profile/matrix/login`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const data: MatrixUser = await response.json();
        const url = `${CHAT_HOST}?accessToken=${data.access_token}&userId=${data.user_id}&deviceId=${data.device_id}&&dev=${IS_DEV}`;
        window.open(url, '_blank');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ProfileContainer>
      <ProfileWrapper>
        <Avatar size="small" path={avatarPath || ''} />

        <NameWrapper>
          <Text weight={600}>
            {profile?.last_name} {profile?.first_name}
          </Text>
          <Text size={14} color="#787D93">
            {profile?.username}
          </Text>
        </NameWrapper>
      </ProfileWrapper>

      {isDoctor && (
        <ProfileTabList>
          <Link to="/newpost">
            <ProfileTab>
              <PostIcon color="#76AC98" />
              <Text weight={600}>Создать пост</Text>
            </ProfileTab>
          </Link>

          <Link to="/profile/cabinet">
            <ProfileTab>
              <img src={checkImgPath} alt="check" />
              <Text weight={600}>Проверка опросов</Text>
            </ProfileTab>
          </Link>

          <button onClick={onOpenChat}>
            <ProfileTab>
              <Text weight={600}>Перейти в чат</Text>
            </ProfileTab>
          </button>
        </ProfileTabList>
      )}

      {!isDoctor && (
        <ProfileTabList>
          <Link to="/medcard">
            <ProfileTab>
              <img src={cardPath} alt="card" />
              <Text weight={600}>Медицинская карта</Text>
            </ProfileTab>
          </Link>

          <Link to="course">
            <ProfileTab>
              <img src={pillsPath} alt="pills" />
              <Text weight={600}>Курсы лекарств</Text>
            </ProfileTab>
          </Link>

          {/* <Link to="calendar">
            <ProfileTab>
              <img src={calendarPath} alt="calendar" />
              <Text weight={600}>Календарь приема лекарств</Text>
            </ProfileTab>
          </Link> */}
        </ProfileTabList>
      )}
      <ButtonWrapper>
        <AppButton title="Выйти" onClick={onLogout} icon={<img src={logoutPath} alt="logout" />} />
      </ButtonWrapper>
    </ProfileContainer>
  );
};
