import React, { FC, PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { COLORS } from '../../../const';

type ButtonProps = {
  title: string;
  fullWidth?: boolean;
  bold?: boolean;
  onClick?: () => void;
  icon?: JSX.Element;
  disabled?: boolean;
  green?: boolean;
};

export const OutlineButton: FC<PropsWithChildren<ButtonProps>> = ({
  title,
  fullWidth,
  bold,
  onClick,
  icon,
  disabled,
  green,
  children,
}) => {
  return (
    <StyledButton
      bold={bold}
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled}
      green={green}
    >
      {icon}
      {children ? children : title}
    </StyledButton>
  );
};

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (p) => !['bold', 'fullWidth', 'green'].includes(p),
})<{
  bold?: boolean;
  fullWidth?: boolean;
  green?: boolean;
}>`
  position: 'relative';
  min-width: ${(props) => (props.fullWidth ? '100%' : '156px')};
  padding: 12px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 16px;
  border: 2px solid ${(props) => (props.green ? COLORS.main_green : COLORS.main_red)};
  color: ${(props) => (props.green ? COLORS.main_green : COLORS.main_red)};
  font-weight: ${(props) => (props.bold ? 600 : 400)};

  &:disabled {
    background-color: ${COLORS.main_grey1};
  }
`;
