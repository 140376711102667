import { styled } from 'styled-components';
import { COLORS } from '../../../const';

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileWrapper = styled.div`
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SettingsTable = styled.div`
  display: flex;
`;

export const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;

  & div {
    padding: 8.5px 0;
  }
`;

export const DataColumn = styled(TitleColumn)`
  width: 100%;
  margin-left: 14px;
`;

export const Input = styled.input`
  margin: 3.5px 0;
  padding: 8px;
  background-color: ${COLORS.main_background};
  font-family: 'Lato', sans-serif;
  border-bottom: 1px solid #cbcedf;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  left: calc(50% - 73px);
  bottom: 100px;
`;

export const ProfileTabList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ProfileTab = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  gap: 12px;
  background-color: #f8f8f8;
`;

export const UploadButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;

  & img {
    margin-right: 8px;
  }
`;

export const FileInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
`;
