import React, { ChangeEvent, useState } from 'react';
import { Text } from '../../components';
import {
  FormContainer,
  FormLogo,
  FormRow,
  FormTitle,
  FormWrapper,
  StyledInput,
  StyledTextarea,
  SubmitButton,
} from './styled';

import logoImgPath from '../../assets/clinics/logo.svg';
import { API } from '../../const';

const initValues = {
  name: '',
  post: '',
  clinic: '',
  phone: '',
  email: '',
  message: '',
};

export const FormBlock = () => {
  const isMobile = window.screen.availWidth < 820;

  const [values, setValues] = useState(initValues);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = async () => {
    try {
      const response = await fetch(`${API}/mail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (response.status === 200) {
        setValues(initValues);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <FormWrapper id="form">
      <FormTitle>
        {isMobile}
        Если вы хотите стать {isMobile ? <br /> : ''} партнером
        <FormLogo>
          <img src={logoImgPath} alt="logo" />
        </FormLogo>
      </FormTitle>
      <Text>Свяжитесь с нами, заполнив форму ниже:</Text>

      <FormContainer>
        <StyledInput name="name" value={values.name} placeholder="Ваше имя" onChange={onChange} />
        <StyledInput name="post" value={values.post} placeholder="Должность" onChange={onChange} />
        <StyledInput
          name="clinic"
          value={values.clinic}
          placeholder="Полное название клиники"
          onChange={onChange}
        />

        <FormRow>
          <StyledInput
            name="phone"
            value={values.phone}
            placeholder="Ваш номер телефона"
            onChange={onChange}
          />
          <StyledInput
            name="email"
            value={values.email}
            placeholder="Ваш email"
            onChange={onChange}
          />
        </FormRow>

        <StyledTextarea
          name="message"
          value={values.message}
          placeholder="Сообщение"
          onChange={onChangeTextarea}
        />

        <SubmitButton
          disabled={
            !values.email || !values.post || !values.clinic || !values.phone || !values.email
          }
          onClick={onSubmit}
        >
          Отправить заявку
        </SubmitButton>
      </FormContainer>
    </FormWrapper>
  );
};
