import { Button } from './styled';
import { Apk, Appstore, Google, Rustore } from '../../components/LandingImg';

export const Buttons = () => {
  const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
  const isAndroid = navigator.userAgent.match(/Android/i);

  return (
    <>
      {!isIOS && !isAndroid && (
        <>
          <Button href="https://apps.rustore.ru/app/com.irinaakhmedzhanova.doctorlyrnexpo">
            <Rustore />
          </Button>
          <Button href="https://play.google.com/store/apps/details?id=com.irinaakhmedzhanova.doctorlyrnexpo">
            <Google />
          </Button>
          <Button href="https://apps.apple.com/app/id6477875598">
            <Appstore />
          </Button>
          <Button href="Doctorly.apk" download>
            <Apk />
          </Button>
        </>
      )}

      {isIOS && (
        <Button href="https://apps.apple.com/app/id6477875598">
          <Appstore />
        </Button>
      )}

      {isAndroid && (
        <>
          <Button href="https://www.rustore.ru/catalog/app/com.irinaakhmedzhanova.doctorlyrnexpo">
            <Rustore />
          </Button>
          <Button href="https://play.google.com/store/apps/details?id=com.irinaakhmedzhanova.doctorlyrnexpo">
            <Google />
          </Button>
          <Button href="Doctorly.apk" download>
            <Apk />
          </Button>
        </>
      )}
    </>
  );
};
