import React from 'react';
import * as Scroll from 'react-scroll';
import { Text } from '../../components';
import {
  WelcomeBlockContainer,
  BlockTitle,
  LogoTitle,
  WelcomeBlockItem,
  WelcomeBlockTitleWrapper,
  WelcomeBlockImage,
  WelcomeWrapper,
  SendButton,
} from './styled';

import logoImgPath from '../../assets/logo.svg';
import clinicImgPath from '../../assets/clinics/phone.png';

const ScrollLink = Scroll.Link;

export const WelcomeBlock = () => {
  return (
    <WelcomeWrapper id="main">
      <WelcomeBlockContainer>
        <WelcomeBlockItem>
          <WelcomeBlockTitleWrapper>
            <BlockTitle>
              Добро пожаловать
              <br />в
            </BlockTitle>
            <LogoTitle>
              <img src={logoImgPath} alt="logo" />
            </LogoTitle>
          </WelcomeBlockTitleWrapper>

          <Text size={18} lineHeight={26}>
            Присоединитесь одними из первых к новой социальной сети в области медицины, в которую
            уже интегрирован сервис телемедицины.
          </Text>
          <Text size={18} lineHeight={26} pb={32}>
            Расскажите о своей компании, привлекайте новых клиентов и используйте Doctorly как
            способ общения с вашими пациентами, повышая качество сервиса!
          </Text>

          <ScrollLink to="form" spy={true} smooth={true}>
            <SendButton>Отправить заявку</SendButton>
          </ScrollLink>
        </WelcomeBlockItem>

        <WelcomeBlockImage>
          <img src={clinicImgPath} alt="phone" />
        </WelcomeBlockImage>
      </WelcomeBlockContainer>
    </WelcomeWrapper>
  );
};
