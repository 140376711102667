import React from 'react';
import { AdvantagesBlock } from './AdvantagesBlock';
import { Header } from './Header';
import { WelcomeBlock } from './WelcomeBlock';
import { Buttons } from './Buttons';
import { ButtonsWrapper, LandingContainer, LandingWrapper, ToolBack, WelcomeBack } from './styled';
import { Footer } from './Footer';
import { ToolBlock } from './ToolBlock';
import { OfferBlock } from './OfferBlock';
import { FormBlock } from './FormBlock';

export const ClinicLandingPage = () => {
  return (
    <LandingContainer>
      <WelcomeBack>
        <LandingWrapper>
          <Header />

          <WelcomeBlock />
        </LandingWrapper>
      </WelcomeBack>
      <LandingWrapper>
        <ButtonsWrapper>
          <Buttons />
        </ButtonsWrapper>
        <AdvantagesBlock />
      </LandingWrapper>

      <ToolBack>
        <LandingWrapper>
          <ToolBlock />
        </LandingWrapper>
      </ToolBack>

      <LandingWrapper>
        <OfferBlock />
      </LandingWrapper>
      <FormBlock />
      <Footer />
    </LandingContainer>
  );
};
