import * as Scroll from 'react-scroll';
import { Text } from '../../components';
import { LogoWhiteIcon } from '../../components/Icons';
import {
  Bottom,
  Copyright,
  FooterContainer,
  FooterInfo,
  FooterLinks,
  FooterWrapper,
} from './styled';

const ScrollLink = Scroll.Link;

export const Footer = () => {
  const isMobile = window.screen.availWidth <= 820;

  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterInfo>
          <Text color="#FFF" weight={500}>
            ИП ДРЕВАЛЬ ДАВИД ДМИТРИЕВИЧ
          </Text>
          <Text color="#FFF" weight={500}>
            ИНН 772984326260
          </Text>
          <Text color="#FFF" weight={500}>
            ОГРНИП 319774600496694
          </Text>
          <Text color="#FFF" weight={500}>
            mail@doctorly.ru
          </Text>
        </FooterInfo>

        <LogoWhiteIcon />
      </FooterWrapper>

      <Bottom>
        <FooterWrapper>
          <FooterLinks>
            <ScrollLink to="advantages" spy={true} smooth={true} offset={-50}>
              <Text size={14} color="#FFF" weight={600}>
                Преимущества
              </Text>
            </ScrollLink>
            <ScrollLink to="forWhom" spy={true} smooth={true}>
              <Text size={14} color="#FFF" weight={600}>
                Для кого?
              </Text>
            </ScrollLink>
            <ScrollLink to="why" spy={true} smooth={true} offset={-50}>
              <Text size={14} color="#FFF" weight={600}>
                Зачем это нужно?
              </Text>
            </ScrollLink>
          </FooterLinks>

          <Copyright>
            <Text size={12} color="#FFF" textAlign={isMobile ? 'center' : 'right'} pt={4}>
              © ИП ДРЕВАЛЬ ДАВИД ДМИТРИЕВИЧ, 2023–2024. Все права защищены.
            </Text>
          </Copyright>
        </FooterWrapper>
      </Bottom>
    </FooterContainer>
  );
};
