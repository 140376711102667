import { styled } from 'styled-components';
import { COLORS } from '../../const';

import backgroundPath from '../../assets/clinics/Background.png';
import background2Path from '../../assets/clinics/cta.png';
import cta2Path from '../../assets/clinics/cta2.png';

export const LandingContainer = styled.div`
  position: relative;
  width: 100vw;
`;

export const LandingWrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const PageContainer = styled.div`
  position: relative;
  max-width: 100vw;
`;

// HEADER

export const HeaderContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 820px) {
    padding-top: 8px;
  }
`;

export const LogoWrapper = styled.div`
  margin-right: 145px;
  padding-top: 12px;

  @media screen and (max-width: 1200px) {
    margin-right: 40px;
  }

  @media screen and (max-width: 972px) {
    margin-right: 0px;
    margin-left: 16px;
  }
`;

export const SignInButton = styled.div`
  width: fit-content;
  padding: 12px 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: #68c378;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #fff;
`;

export const SendButton = styled.div`
  width: fit-content;
  padding: 12px 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: #68c378;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #fff;

  @media screen and (max-width: 972px) {
    width: 100%;
  }
`;

export const MenuContainer = styled.div`
  margin-left: 135px;
  display: flex;
  align-items: center;

  & a + a {
    margin-left: 32px;
  }

  @media screen and (max-width: 1200px) {
    margin-left: 40px;
  }

  @media screen and (max-width: 972px) {
    display: none;
  }
`;

export const MobileHeader = styled.div`
  display: none;

  @media screen and (max-width: 972px) {
    margin-right: 16px;
    display: flex;
  }
`;

// COMMON

export const BlockTitleWrapper = styled.div`
  position: relative;
`;

export const BlockTitle = styled.p`
  display: block;
  font-size: 44px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  line-height: 50px;
  color: #35373f;

  @media screen and (max-width: 972px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const LightBlockTitle = styled(BlockTitle)`
  color: #f5f5f5;

  &:hover {
    color: #f5f5f5;
  }
`;

export const BlockItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & span {
    margin: 24px 0px;

    @media screen and (max-width: 910px) {
      margin: 21px 0px;
    }
  }

  & img {
    @media screen and (max-width: 910px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 910px) {
    margin-bottom: 23px;
  }
`;

// WELCOME BLOCK

export const LogoTitle = styled.div`
  position: absolute;
  bottom: -16px;
  left: 40px;

  @media screen and (max-width: 972px) {
    bottom: -10px;
    left: 20px;
  }

  & img {
    width: 180px;

    @media screen and (max-width: 972px) {
      width: 98px;
    }
  }
`;

export const WelcomeWrapper = styled.div`
  position: relative;
  margin: 84px 0px 24px 20px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1366px) {
    max-width: 100%;
  }

  @media screen and (max-width: 1024px) {
    margin: 84px 40px 0 40px;
  }

  @media screen and (max-width: 820px) {
    margin: 84px 16px 0 16px;
  }
`;

export const WelcomeBack = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;
  background: url(${backgroundPath});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  overflow-x: hidden;

  @media screen and (max-width: 972px) {
    width: 100vw;
    height: 100%;
    background: url(${backgroundPath});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: -200px;
    overflow-x: hidden;
  }
`;

export const WelcomeBlockContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 972px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

export const WelcomeBlockItem = styled.div`
  max-width: 696px;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 2000px) {
    padding-top: 218px;
  }

  @media screen and (max-width: 1366px) {
    max-width: 500px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 300px;
  }

  @media screen and (max-width: 972px) {
    max-width: 100%;
    padding: 32px 0;
  }
`;

export const WelcomeBlockTitleWrapper = styled(BlockTitleWrapper)`
  position: absolute;
  top: 35px;
  width: 437px;

  @media screen and (max-width: 2000px) {
    top: 96px;
  }

  @media screen and (max-width: 972px) {
    top: -48px;
  }
`;

export const WelcomeBlockImage = styled.div`
  max-width: 488px;
  width: 488px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    object-fit: cover;
  }
`;

export const ButtonsWrapper = styled.div`
  padding-bottom: 35px;
  display: flex;
  gap: 42px;
  justify-content: center;

  @media screen and (max-width: 820px) {
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }
`;

export const Button = styled.a`
  width: 200px;
  height: 52px;
  padding: 14px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #000000;

  @media screen and (max-width: 820px) {
    width: 288px;
  }
`;

// ADVANTAGES

export const AdvantagesContainer = styled.div`
  margin: 96px auto;
  max-width: 800px;

  @media screen and (max-width: 820px) {
    margin: 48px 16px;
    max-width: 100%;
  }
`;

export const AdvantageTitle = styled.p`
  padding-bottom: 8px;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
`;

export const AdvantageItems = styled.div`
  margin-top: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 64px;

  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
    row-gap: 48px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

// TOOl

export const ToolBack = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;
  background: url(${background2Path});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  overflow-x: hidden;

  @media screen and (max-width: 820px) {
    background: url(${cta2Path});
    background-size: 100% auto;
    background-position-y: top;
  }

  @media screen and (max-width: 480px) {
    background-size: auto 100%;
  }
`;

export const ToolWrapper = styled.div`
  margin: 135px 12px 72px;
  max-width: 592px;

  @media screen and (max-width: 1200px) {
    max-width: 400px;
  }

  @media screen and (max-width: 820px) {
    margin: 48px 16px;
    max-width: 100%;
  }

  & img {
    max-width: 100%;
  }
`;

export const ToolButtons = styled.div`
  margin: 32px 0px;
  display: flex;
  gap: 12px;

  @media screen and (max-width: 820px) {
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }
`;

// TOOl

export const Offers = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 64px;

  @media screen and (max-width: 820px) {
    margin-top: 48px;
    gap: 48px;
  }
`;

export const OffersRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media screen and (max-width: 820px) {
    gap: 12px;
  }
`;

export const OfferItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

// FORM

export const FormWrapper = styled.div`
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #edeff5;
`;

export const FormTitle = styled.div`
  position: relative;
  margin-left: -150px;
  padding-bottom: 4px;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;

  @media screen and (max-width: 820px) {
    margin-left: 0;
    font-size: 28px;
    line-height: 32px;
  }
`;

export const FormLogo = styled.div`
  position: absolute;
  top: 7px;
  right: -150px;

  @media screen and (max-width: 820px) {
    top: 34px;
    right: -6px;
  }
`;

export const FormContainer = styled.div`
  max-width: 736px;
  width: 736px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 820px) {
    max-width: 100%;
    width: 100%;
    padding: 0 16px;
  }
`;

export const StyledInput = styled.input.withConfig({
  shouldForwardProp: (p) => !['error', 'wide'].includes(p),
})<{
  error?: boolean;
  wide?: boolean;
}>`
  -webkit-appearance: none;
  width: 100%;
  height: 48px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${COLORS.main_grey1};
  font-size: 14px;
  color: ${(props) => (props.error ? '#E74D3F' : '#000')};

  &::placeholder {
    color: #babdcc;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  height: 160px;
  padding: 8px;
  border: 1px solid ${COLORS.main_grey1};
  border-radius: 8px;
  font-size: 14px;
  resize: none;

  &::placeholder {
    color: #babdcc;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }
`;

export const SubmitButton = styled.button`
  width: fit-content;
  padding: 12px 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: #68c378;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #fff;

  &:disabled {
    background: #babdcc;
  }

  @media screen and (max-width: 972px) {
    width: 100%;
  }
`;

// FOOTER

export const FooterContainer = styled.div`
  width: 100%;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(90deg, #147a54 20%, #0b4831 80%);
`;

export const FooterWrapper = styled.div`
  width: 941px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 972px) {
    padding: 0 30px;
    width: 100%;
    flex-direction: column-reverse;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${COLORS.main_grey1};

  @media screen and (max-width: 820px) {
    width: 100%;
    padding: 18px;
  }
`;

export const FooterInfo = styled.div`
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 820px) {
    padding-top: 16px;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 34px;

  @media screen and (max-width: 820px) {
    display: none;
  }
`;

export const Copyright = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 700px) {
    justify-content: center;
  }
`;
