import React, { useState, FC } from 'react';
import Picker from 'react-mobile-picker';
import { OutlineButton, Text } from '../../components';
import { PickerButtons, PickerContainer, Option, PickerPrice } from './styled';
import { COLORS } from '../../const';

type Props = {
  onChange: (value: { type: string }) => void;
  pickerValue: { type: string };
};

const selections: { [key: string]: string[] } = {
  type: ['3', '6', '12'],
};

const PRICE: { [key: string]: string } = {
  '3': '2400руб.',
  '6': '1500 руб.',
  '12': '900 руб.',
};

const TIME: { [key: string]: string } = {
  '3': 'на 12 месяцев',
  '6': 'на 6 месяцев',
  '12': 'на 3 месяца',
};

export const Select: FC<Props> = ({ pickerValue, onChange }) => {
  const [open, setOpen] = useState(false);

  const openPicker = () => {
    setOpen(true);
  };

  const closePicker = () => {
    setOpen(false);
  };

  return (
    <>
      <OutlineButton title="" green fullWidth onClick={openPicker}>
        <PickerPrice>
          <Text size={14} lineHeight={21} color={COLORS.main_green}>
            {PRICE[pickerValue.type]}
          </Text>

          <Text size={14} lineHeight={21} color={COLORS.main_green}>
            {TIME[pickerValue.type]}
          </Text>
        </PickerPrice>
      </OutlineButton>

      {open && (
        <PickerContainer>
          <PickerButtons>
            <button onClick={closePicker}>
              <Text size={16} color={COLORS.main_green}>
                Отмена
              </Text>
            </button>
            <button onClick={closePicker}>
              <Text size={16} color={COLORS.main_green}>
                Сoхранить
              </Text>
            </button>
          </PickerButtons>

          <Picker value={pickerValue} onChange={onChange}>
            {Object.keys(selections).map((name) => (
              <Picker.Column key={name} name={name}>
                {selections[name].map((option) => (
                  <Picker.Item key={option} value={option}>
                    {({ selected }) => (
                      <Option selected={selected}>
                        {PRICE[option]} {TIME[option]}
                      </Option>
                    )}
                  </Picker.Item>
                ))}
              </Picker.Column>
            ))}
          </Picker>
        </PickerContainer>
      )}
    </>
  );
};
